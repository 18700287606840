import React, {useState} from 'react'
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'

import BoludaPageHeader from "./pageheader/BoludaPageHeader";
import Private from "./Private";
import LoginSeite from "./auth/LoginSeite";
import LogoutSeite from "./auth/LogoutSeite";
import Benutzerliste from "./settings/benutzer/Benutzerliste";
import BenutzerdetailSeite from "./settings/benutzer/BenutzerdetailSeite";
import ToernBearbeitenSeite from "./toerns/ToernBearbeitenSeite";
import ToernRuhezeitenListe from "./toerns/ToernRuhezeitenListe";
import ToernRuhezeitenBearbeitenSeite from "./toerns/ToernRuhezeitenBearbeitenSeite";
import ToernDetailSeite from "./toerns/ToernDetailSeite";
import SettingsMenu from "./settings/SettingsMenu";
import SettingsSeite from "./settings/SettingsSeite";
import TarifUebersichtSeite from "./settings/tarife/TarifUebersichtSeite";
import Schlepperliste from "./settings/schlepper/Schlepperliste";
import TarifzeitraumBearbeitenSeite from "./settings/tarife/TarifzeitraumBearbeitenSeite";
import TarifzeitraumUebersichtSeite from "./settings/tarife/TarifzeitraumUebersichtSeite";
import BordpersonalListe from "./bordpersonal/BordpersonalListe";
import BordpersonalAbschlaegeDetailseite from "./bordpersonal/BordpersonalAbschlaegeDetailseite";
import BordpersonalSeite from "./bordpersonal/BordpersonalSeite";
import MeineToernsListe from "./toerns/MeineToernsListe";
import ToernPruefenListe from "./toerns/ToernPruefenListe";
import ToernSeitenMenu from "./toerns/ToernSeitenMenu";
import authenticationService from "../services/AuthenticationService";
import AktiveToerns from "./toerns/AktiveToerns";
import AlleToernsListe from "./toerns/AlleToernsListe";
import PasswortVergessenSeite from "./auth/PasswortVergessenSeite";
import {App} from "antd";
import PasswortTokenSeite from "./auth/PasswortTokenSeite";

const BoludaApp = () => {
  const [loginState, setLoginState] = useState(false);

  return (
    <App>
      <Router>
        <BoludaPageHeader loginState={loginState}/>

        <Routes>
          <Route path="/" element={
            <Private>
              <Navigate replace to="/_/toerns" />
            </Private>
          }/>
          <Route path="/_/login" element={
            <LoginSeite onLogin={setLoginState}/>
          }/>
          <Route path="/_/logout" element={
            <LogoutSeite onLogout={setLoginState}/>
          }/>

          <Route path="/_/passwort-vergessen" element={
            <PasswortVergessenSeite />
          }/>

          <Route path="/_/passwort-token/:token?" element={
            <PasswortTokenSeite />
          }/>

          <Route path="/_/toerns" element={<ToernSeitenMenu/>}>
            {<Route index element={
              authenticationService.isBordPersonal() ?
                <Navigate replace to="/_/toerns/aktive" /> :
                <Navigate replace to="/_/toerns/eingereicht" />
            }/>}
            <Route path='aktive' element={<AktiveToerns/>}/>
            <Route path='meine/:jahr?/:monat?' element={<MeineToernsListe/>}/>
            <Route path='eingereicht/:jahr?/:monat?' element={<ToernPruefenListe/>}/>
            <Route path='alle/:jahr?/:monat?' element={<AlleToernsListe/>}/>
          </Route>

          <Route path="/_/bordpersonal" element={<Private><BordpersonalSeite/></Private>}>
            <Route index element={<BordpersonalListe/>}/>
            <Route path='details/:id' element={<BordpersonalAbschlaegeDetailseite/>}/>
          </Route>

          <Route path="/_/toern/:toernId" element={<ToernDetailSeite/>}>
            <Route index element={<ToernBearbeitenSeite/>}/>
            <Route path='ruhezeiten' element={<ToernRuhezeitenListe/>}/>
            <Route path='ruhezeit/:ruhezeitId' element={<ToernRuhezeitenBearbeitenSeite/>}/>
          </Route>

          <Route path="/_/settings" element={<Private><SettingsSeite/></Private>}>
            <Route index element={<Private><SettingsMenu/></Private>}/>
            <Route path='tarife' element={<TarifUebersichtSeite/>}>
              <Route index element={<TarifzeitraumUebersichtSeite/>}/>
              <Route path='tarifzeitraum/:id' element={<TarifzeitraumBearbeitenSeite/>}/>
              <Route path='tarifzeitraum/:id' element={<TarifzeitraumBearbeitenSeite/>}/>
            </Route>
            <Route path='benutzer' element={<Benutzerliste/>}/>
            <Route path='benutzer/details/:id?/:tab?' element={<BenutzerdetailSeite/>}/>
            <Route path='schlepper' element={<Schlepperliste/>}/>
          </Route>
        </Routes>
      </Router>
    </App>
  )
}

export default BoludaApp
