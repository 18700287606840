import React, {useState} from 'react'
import {Menu} from 'antd';
import {Link, Outlet} from 'react-router-dom';
import authenticationService from "../../services/AuthenticationService";
import {ClockCircleOutlined} from "@ant-design/icons";
import {MeinToernIcon, ToernIcon} from "../icons/CustomIcons";

const ToernSeitenMenu = () => {
  const [current, setCurrent] = useState('meine');

  const onMenuItemSelected = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };

  var menuitems = []
  if (authenticationService.isBordPersonal()) {
    menuitems.push({
        label: (
          <Link to="/_/toerns/aktive">Aktuelle Törns</Link>
        ),
        key: 'meine',
        icon: <MeinToernIcon />,
      }
    )
    menuitems.push({
        label: (
          <Link to="/_/toerns/meine">Meine Törns</Link>
        ),
        key: 'zeit',
        icon: <ToernIcon/>,
      }
    )
  }
  if (authenticationService.hatEineRolle(['heuer', 'admin'])) {
    menuitems.push({
        label: (
          <Link to="/_/toerns/eingereicht">Törns zur Prüfung</Link>
        ),
        key: 'pruefen',
        icon: <ClockCircleOutlined/>,
      }
    )
    menuitems.push({
        label: (
          <Link to="/_/toerns/alle">Alle Törns</Link>
        ),
        key: 'alle',
        icon: <ClockCircleOutlined/>,
      }
    )
  }


  return (
    <>
      <Menu onClick={onMenuItemSelected} selectedKeys={[current]} mode={"horizontal"} items={menuitems}>
      </Menu>
      <Outlet />
    </>

  )
}
export default ToernSeitenMenu

