import axios from 'axios'
import jwt_decode from 'jwt-decode'
import ServiceConfig from "./ServiceConfig";

const API_URL = ServiceConfig.API_URL;

export const USERNAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser';

class AuthenticationService {

    // TODO catch?
    // TODO: Expiry testen!

    doLogin(username, password, remember) {
        return axios.post(`${API_URL}/api/v1/auth/signin`, {
            "email": username,
            "password": password
        }).then((response) => {
            this._saveLoginData(username, response.data.token, remember);
        });
    }

    doLogout() {
        sessionStorage.removeItem(USERNAME_SESSION_ATTRIBUTE_NAME);
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        this._setupAxiosInterceptors(null);
    }

    isUserLoggedIn(remember = true) {
        let user = sessionStorage.getItem(USERNAME_SESSION_ATTRIBUTE_NAME)
        let sessionToken = sessionStorage.getItem('token')
        if(user === null) {
            if (remember) {
                try {
                    const token = localStorage.getItem('token');
                    if (token != null) {
                        var decoded = jwt_decode(token);
                        console.log("DECODED", decoded);
                        console.log("TIME", Math.floor(Date.now() / 1000));
                        if (Math.floor(Date.now() / 1000) < decoded.exp) {
                            console.log("EINLOGGEN!")
                            this._saveLoginData(decoded.sub, token, true);
                            return true;
                        } else {
                            localStorage.removeItem('token');
                        }
                    }
                } catch (e) {
//                    console.log.("Fehler", e);
                }
            }
            return false
        }
        if ((axios.interceptors.request.handlers.length === 0) && sessionToken) {
            this._setupAxiosInterceptors(this._createJwtToken(sessionToken));
        }
        return true
    }

    getRang() {
        try {
            var decoded = jwt_decode(this._holeSessionToken());
            return decoded.rang;
        } catch (e) {
            return "";
        }
    }

    getRollen() {
        try {
            var decoded = jwt_decode(this._holeSessionToken());
            return decoded.rollen;
        } catch (e) {
            //console.log("Keine Rollen", e);
            return [];
        }
    }

    getInitialen() {
        try {
            var decoded = jwt_decode(this._holeSessionToken());
            return decoded.initialen;
        } catch (e) {
            return "";
        }
    }

    isBordPersonal() {
        return (this.getRollen().includes('bordpersonal'));
    }

    hatEineRolle(checkRollen) {
        if (checkRollen.length===0) return false;
        return this.getRollen().some(r=> checkRollen.includes(r))
    }

    _holeSessionToken() {
        const token = sessionStorage.getItem('token')
        if (!token) {
            throw Error("Keine Anmeldedaten in Session");
        }
        return token;
    }

    _saveLoginData(username, token, remember) {
        sessionStorage.setItem(USERNAME_SESSION_ATTRIBUTE_NAME, username)
        sessionStorage.setItem('token', token)
        if (remember) {
            localStorage.setItem('token', token);
        } else {
            localStorage.removeItem('token');
        }
        this._setupAxiosInterceptors(this._createJwtToken(token))
    }

    _createJwtToken(token) {
        return `Bearer ${token}`
    }

    _setupAxiosInterceptors(token) {
        axios.interceptors.request.clear();
        if (token !== null) {
            axios.interceptors.request.use(
              (config) => {
                  if(this.isUserLoggedIn(false)) {
                      config.headers.authorization = token
                  }
                  return config
              }
            )
        }
    }


    /*
    isUserLoggedIn() {
        let user = sessionStorage.getItem(USERNAME_SESSION_ATTRIBUTE_NAME)
        if(user === null) return false
        return true
    }
    */

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USERNAME_SESSION_ATTRIBUTE_NAME)
        if(user === null) return ''
        return user
    }

  createPasswortVergessenToken(email) {
      return axios.post(`${API_URL}/api/v1/auth/passwort-vergessen`, {
          "email": email,
      });
  }

  resetPassword(email, password, token) {
      return axios.post(`${API_URL}/api/v1/auth/passwort-erneuern`, {
          "email": email,
          "password": password,
          "token": token
      });
  }

}

const authenticationService =  new AuthenticationService();
export default authenticationService;