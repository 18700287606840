import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import authenticationService from '../../services/AuthenticationService'
import {PageHeader} from "@ant-design/pro-layout";
import {Avatar, Dropdown, Menu} from "antd";
import {ClockCircleOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import './BoludaPageHeader.css';
import {SeamanIcon} from "../icons/CustomIcons";

const BoludaPageHeader = (props) => {

  const isUserLoggedIn = authenticationService.isUserLoggedIn()

  const itemsLoggedOut = [
    {
      key: '1',
      label: (<Link to={"/_/login"}>anmelden</Link>),
    },
  ];

  const itemsLoggedIn = [
    {
      key: '1',
      label: (<Link to={"/_/mein_konto"}>Mein Konto</Link>),
    },
    {
      key: '2',
      label: (
        <Link to="/_/logout">abmelden</Link>
      ),
    }
  ];

  const items = isUserLoggedIn ? itemsLoggedIn : itemsLoggedOut;

//  const avatar = isUserLoggedIn ? <p><Avatar style={{backgroundColor: '#87d068'}} icon={<UserOutlined/>}/></p> :
//    <Avatar icon={<UserOutlined/>}/>

  /*
  const avatar = isUserLoggedIn ?
    <p>
      { authenticationService.isBordPersonal() ?
        <Avatar style={{backgroundColor: '#e33c40'}} icon={<UserOutlined/>}/> :
        <Avatar style={{backgroundColor: '#162847'}} icon={<UserOutlined/>}/>
      }
    </p> :
    <Avatar style={{backgroundColor: '#cccccc'}} icon={<UserOutlined/>}/>
*/

  const avatar = () => {
    if (isUserLoggedIn) {
      var farbe = authenticationService.isBordPersonal() ? '#e33c40' : '#162847'
      var initialen = authenticationService.getInitialen();
      if (initialen) {
        return <p><Avatar style={{backgroundColor: farbe}} >{initialen}</Avatar></p>
      } else {
        return <p><Avatar style={{backgroundColor: farbe}} icon={<UserOutlined/>}/></p>
      }
    } else {
      return <Avatar style={{backgroundColor: '#cccccc'}} icon={<UserOutlined/>}/>
    }
  }

  const [menuMode, setMenuMode] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkWindowSize = () => {
    if (window.innerWidth > 599) {
      setMenuMode("horizontal");
    } else {
      setMenuMode("inline");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkWindowSize);
    checkWindowSize();
    return () => window.removeEventListener("resize", checkWindowSize);
  }, [checkWindowSize]);

  return (
    <>
      <PageHeader
        avatar={{src: "/img/logo-boluda.png", alt: "B"}}
        title="Boluda"
        subTitle=""
        extra={<>

          <div>

            <Menu className={'bsMenu'} style={{minWidth: "340px"}} selectedKeys={['mail']} mode={menuMode} items={[
              {
                label: (
                  <Link to="/">Törns</Link>
                ),
                key: 'stunden',
                icon: <ClockCircleOutlined/>,

              },
              {
                //TODO: wird nicht aktualisiert nach login! (Evtl. navigate?)
                label: (
                  (authenticationService.hatEineRolle(['heuer', 'admin'])) ?
                    <Link to="/_/bordpersonal">Bordpersonal</Link>
                    :
                    <span>Bordpersonal</span>
              ),
                disabled: !authenticationService.hatEineRolle(['heuer', 'admin']),
                key: 'benutzer',
                icon: <SeamanIcon/>,
              },
              {
                key: 'settings',
                label: (
                  (authenticationService.hatEineRolle(['heuer', 'admin'])) ?
                    <Link to="/_/settings">Einstellungen</Link>
                    :
                    <span>Einstellungen</span>
                ),
                disabled: !authenticationService.hatEineRolle(['heuer', 'admin']),
                key: 'einstellungen',
                icon: <SettingOutlined/>,
              }
            ]}>

            </Menu>
          </div>

          <Dropdown menu={{items}}>{avatar()}</Dropdown></>
        }
      >
      </PageHeader>
    </>
  )
}

export default BoludaPageHeader