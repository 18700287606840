import React, {useEffect, useState} from 'react'
import {Button, Layout, Table, message, Space, DatePicker, Tag} from 'antd';
import {Link, useOutletContext} from "react-router-dom";
import benutzerService from "../../services/BenutzerService";
import _ from "lodash";
import LadeIndikator from "../LadeIndikator";
import dayjs from "dayjs";
import {AbschlagIcon, SeamanIcon} from "../icons/CustomIcons";
import TableTitle from "../base/TableTitle";

const { Content } = Layout;

const BordpersonalListe = () => {
  const [jahr, setJahr] = useOutletContext();

  const [benutzerliste, setBenutzerliste] = useState([]);
  const [loading, setloading] = useState(true);

  const [messageApi, contextHolder] = message.useMessage();

  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");

  const error = (msg) => {
    messageApi.open(    {
      type: 'error', content: msg,
    });
  }

  const handleChange = (pagination, filters, sorter) => {
    setFeldFilter(filters);
  };

  useEffect(() => {
    getBenutzerliste();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("Jahr!!!")
    getBenutzerliste();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jahr]);


  const getBenutzerliste = async () => {
    setloading(true);
    await benutzerService.getBordpersonalliste(jahr).then(
      res => {
        setloading(false);
        setBenutzerliste(
          res.data.map(row => ({
            nachname: row.nachname,
            vorname: row.vorname,
            email: row.email,
            abschlaege: row.abschlaege,
            sfn: row.sfn,
            id: row.id,
            key: row.id
          }))
        );
      }
    ).catch((e) => {
      error(`Beim Laden der Benutzer ist ein Fehler aufgetreten`);
      console.error(e);
    });
  };

  const filterData = benutzerliste => formatter => benutzerliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const columns=[
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: 'Vorname',
      dataIndex: 'vorname',
      key: 'vorname',
      sorter: (a, b) => a.vorname.localeCompare(b.vorname),
      filters: _.uniqWith(filterData(benutzerliste)(i => i.vorname), _.isEqual),
      filteredValue: feldFilter.vorname || null,
      onFilter: (value, record) => record.vorname.includes(value),
    },
    {
      title: 'Nachname',
      dataIndex: 'nachname',
      key: 'nachname',
      sorter: (a, b) => a.nachname.localeCompare(b.nachname),
      filters: _.uniqWith(filterData(benutzerliste)(i => i.nachname), _.isEqual),
      filteredValue: feldFilter.nachname || null,
      onFilter: (value, record) => record.nachname.includes(value),
    },
    {
      title: 'SFN-Zuschläge',
      dataIndex: 'abschlaege',
      key: 'abschlaege',
      render: (s) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(s)
    },
    {
      title: 'SFN',
      dataIndex: 'sfn',
      key: 'sfn',
      render: (s) => new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(s)
    },
    {
      title: 'Diff',
      align: 'right',
      dataIndex: 'sfn',
      sorter: (a, b) => (a.abschlaege ? 1 - (a.sfn / a.abschlaege) : (a.sfn ? 1 : 0)) > (b.abschlaege ? 1 - (b.sfn / b.abschlaege) : (b.sfn ? 1 : 0)),
      key: 'abweichung',
      render: (s, record) => {
        var abw = record.abschlaege ? (s / record.abschlaege) : (s ? 1 : 0)
        var content = new Intl.NumberFormat("de-DE", {
          style: "percent",
          minimumFractionDigits: 1,
          maximumFractionDigits: 1
        }).format(abw);
        if (abw < 0.7) {
          return <Tag style={{textAlign: 'right', width: '60px'}} color="red">{content}</Tag>
        } else if (abw < 0.9 || abw > 1.1) {
          return <Tag style={{ textAlign:'right', width:'60px'}} color="yellow">{content}</Tag>
        } else {
          return <Tag style={{ textAlign:'right', width:'60px'}}>{content}</Tag>
        }
      }
    },
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) =>
        record.nachname.toString().toLowerCase().includes(value.toLowerCase()) ||
        record.vorname.toString().toLowerCase().includes(value.toLowerCase())
      ,
      render: (id, record) => <Space>
        <Link to={"/_/bordpersonal/details/" + id }><Button icon={<AbschlagIcon />} /></Link>
      </Space>,
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex'}} >
          <Space>
            {/*
            <Link to={"/_/bordpersonal/details/neu"}><Button icon={<UserAddOutlined/>} >Benutzer anlegen</Button></Link>
            */}
            <DatePicker defaultValue={dayjs()} format={'YYYY'} picker="year"
                                    onChange={ (ev) => setJahr(ev ? dayjs(ev.$d).year() : dayjs().year() ) } />
          </Space>

        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator />
          ) : (
            <Table
              title={() => (<TableTitle
                  icon={<SeamanIcon/>}
                  filtervalues={
                    benutzerliste
                      .reduce(function(acc, b) {
                        acc.push(b.vorname);
                        acc.push(b.nachname);
                        return acc;
                      }, [])
                      .filter((value, index, array) => array.indexOf(value) === index)
                      .map(s => ({ label: s, value: s}))
                  }
                  filter onFilter={setGlobalFilter} >Bordpersonal</TableTitle>)}
                   columns={columns} dataSource={benutzerliste} onChange={handleChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default BordpersonalListe