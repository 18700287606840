import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, DatePicker, Form, Input, Layout, message, Row, Select, Space} from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";

import benutzerService from '../../../services/BenutzerService'
import tarifService from '../../../services/TarifService'
import LadeIndikator from "../../LadeIndikator";
import {ArrowLeftOutlined, UserOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import authenticationService from "../../../services/AuthenticationService";
import FormHeader from "../../base/FormHeader";

const {Content} = Layout;

const layout = {
  labelCol: {
    span: 8,
  }, wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0, span: 24,
    },
    sm: {
      offset: 8, span: 16,
    },
  }
};

const BenutzerstammdatenSeite = (props) => {
  let benutzer = props.benutzer;
  let setBenutzer = props.setBenutzer;
  let {id} = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [tarifgruppen, setTarifgruppen] = useState(false);

  useEffect(() => {
    ladeTarifgruppen();
  }, []);

  useEffect(() => {
    syncBenutzerForm()
  }, [benutzer]);

  const syncBenutzerForm = () => {
    form.setFieldValue('email', benutzer.email)
    form.setFieldValue('nachname', benutzer.nachname)
    form.setFieldValue('vorname', benutzer.vorname)
    form.setFieldValue('rang', benutzer.rang)
    form.setFieldValue('id', benutzer.id)
    form.setFieldValue('eintrittsDatum', benutzer.eintrittsDatum ? dayjs(benutzer.eintrittsDatum) : null)
    form.setFieldValue('austrittsDatum', benutzer.austrittsDatum ? dayjs(benutzer.austrittsDatum) : null)
    form.setFieldValue('rollen', benutzer.rollen)
    form.setFieldValue('tarifgruppe', benutzer.tarifgruppe ? benutzer.tarifgruppe.id : null)
  }

  const handleError = (e, msg) => {
    if (e.response && e.response.data && e.response.data.status === 401) {
      message.error(`Ihre Nutzersitzung ist abgelaufen`);
      authenticationService.doLogout();
      navigate('/_/login');
    } else {
      message.error(msg ? msg : `Beim Laden der Daten ist ein Fehler aufgetreten`);
    }
  }

  const ladeTarifgruppen = async () => {
    setLoading(true);
    await tarifService.getTarifgruppen().then(res => {
      setLoading(false);
      let tg = res.data.map(row => ({
        label: row.gruppenname, value: row.id
      }))
      tg.unshift({label: "-", value: null})
      setTarifgruppen(tg);
    }).catch((e) => {
      setLoading(false);
      handleError(e, `Beim Laden der Tarifgruppen ist ein Fehler aufgetreten`);
    });
  }

  const saveBenutzer = (formData) => {
    setLoading(true);

    let data = formData;
    data.tarifgruppe = formData.tarifgruppe ? {'id': formData.tarifgruppe} : null;

    props.setBenutzer(data);
    const future = (id === 'neu') ? benutzerService.createBenutzer(data) : benutzerService.updateBenutzer(id, data);

    future
      .then(res => {
        showInfo("Die Benutzerdaten wurden gespeichert");
        if (id === 'neu') {
          navigate(`/_/settings/benutzer/details/${res.data.id}`, {replace: true});
          setBenutzer(res.data);
        }
        setLoading(false);
      })
      .catch((ex) => {
        setLoading(false);
        // Error
        if (ex.response) {
          const errs = ex.response.data;
          for (let k of Object.keys(errs)) {
            form.setFields([{name: k, errors: [errs[k]]}]);
          }
          if (ex.response && ex.response.data && ex.response.data.status === 500) {
            handleError(ex, 'Der Server konnte Ihre Daten nicht verarbeiten');
          } else {
            handleError(ex, 'Bitte prüfen Sie die Eingaben und versuchen Sie es erneut');
          }
        } else {
          handleError(ex, "Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie die Eingaben und versuchen Sie es erneut");
        }
      })
  };

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }

  const onReset = () => {
    syncBenutzerForm();
  };

  return (<>
    {contextHolder}
    <Layout>
      <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex'}}>
        <Link to={"/_/settings/benutzer"}><Button icon={<ArrowLeftOutlined/>}>Zur Übersicht</Button></Link>
      </Layout.Header>
      <Content>
        {loading ? (<LadeIndikator/>) : (

          <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={saveBenutzer}
            style={{
              maxWidth: 600,
            }}
          >
            <FormHeader icon={<UserOutlined/>}>Benutzer {id === "neu" ? "anlegen" : "bearbeiten"}</FormHeader>

            <Form.Item name={'id'} hidden={true}/>

            <Form.Item
              name="email"
              label="Email"
              rules={[{
                required: true,
              },]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              name="vorname"
              label="Vorname"
              rules={[{
                required: true,
              },]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
              name="nachname"
              label="Nachname"
              rules={[{
                required: true,
              },]}
            >
              <Input/>
            </Form.Item>

            {id === "neu" ? <Form.Item
              name="passwort"
              label="Passwort"
              rules={[{
                required: true,
              },]}
            >
              <Input/>
            </Form.Item> : ""}

            <Form.Item
              name="tarifgruppe"
              label="Tarifgruppe">
              <Select options={tarifgruppen}/>
            </Form.Item>

            <Form.Item
              name="rang"
              label="Rang">
              <Select>
                <Select.Option value="">-</Select.Option>
                <Select.Option value="Kapitaen">Kapitän</Select.Option>
                <Select.Option value="Maschinist">Maschinist</Select.Option>
                <Select.Option value="Steuermann">Steuermann</Select.Option>
                <Select.Option value="Seemann">Seemann</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item name="rollen" label="Rollen">
              <Checkbox.Group>
                <Row>
                  <Col span={8}>
                    <Checkbox value="admin" style={{lineHeight: '32px'}}>
                      Admin
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="bordpersonal" style={{lineHeight: '32px'}}>
                      Bord
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="heuer" style={{lineHeight: '32px'}}>
                      Heuer
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="hsqe" style={{lineHeight: '32px'}}>
                      HSQE
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item label="Eintrittsdatum" name={"eintrittsDatum"}>
              <DatePicker
                format={"DD.MM.YYYY"}
                style={{width: 'calc(100% - 8px)'}}
              />
            </Form.Item>

            <Form.Item label="Austrittsdatum" name={"austrittsDatum"}>
              <DatePicker
                format={"DD.MM.YYYY"}
                style={{width: 'calc(100% - 8px)'}}
              />
            </Form.Item>

            <Form.Item {...tailLayout}>

              <Space>
                <Link to={"/_/settings/benutzer"}><Button icon={<ArrowLeftOutlined/>}>Zur Liste</Button></Link>
                <Button type="primary" htmlType="submit">
                  Speichern
                </Button>
                <Button htmlType="button" onClick={onReset}>
                  Reset
                </Button>
              </Space>
            </Form.Item>
          </Form>)}
      </Content>
    </Layout>
  </>)
}
export default BenutzerstammdatenSeite
