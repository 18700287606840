import React, {useEffect, useState} from 'react'
import { useOutletContext } from "react-router-dom";
import {
  AutoComplete,
  Avatar,
  Button, Col,
  DatePicker,
  Form, Input,
  InputNumber,
  Layout,
  message, Popconfirm, Row,
  Select,
  Space, Tag,
} from 'antd';
import {useNavigate} from 'react-router-dom';
import toernService from "../../services/ToernService";
import schlepperService from "../../services/SchlepperService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import {
  FilePdfOutlined,
  MessageOutlined,
  RollbackOutlined, SaveOutlined, SendOutlined
} from '@ant-design/icons';
import {Comment} from '@ant-design/compatible';
import LadeIndikator from "../LadeIndikator";
import './ToernBearbeitenSeite.css';

import { CookiesProvider, useCookies } from 'react-cookie'

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {red} from "@ant-design/colors";
import {DlIcon} from "../icons/CustomIcons";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Berlin');

const {Content} = Layout;

dayjs.locale('de')

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 0,
      span: 24
    },
    sm: {
      span: 16,
      offset: 8
    },
    md: {
      span: 16,
      offset: 8
    },
    lg: {
      span: 16,
      offset: 8
    }
  }
};

const ToernBearbeitenSeite = () => {
  const [, setCookie] = useCookies(['tug'])
  const [toern, setToern, loading] = useOutletContext();

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [kommentarForm] = Form.useForm();

  const [einreichenDisabled, setEinreichenDisabled] = useState(true);

  const [formDirty, setFormDirty] = useState(false);

  const [schlepper, setSchlepper] = useState([]);

  useEffect(() => {
    getSchlepperFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldValue('toernId', toern.id)
    form.setFieldValue('startzeit', dayjs(toern.startzeit))
    form.setFieldValue('endezeit', toern.endezeit ? dayjs(toern.endezeit) : null)
    form.setFieldValue('schlepper', toern.schlepper)
    form.setFieldValue('rang', toern.rang)
    form.setFieldValue('hafentage', toern.hafentage)
    form.setFieldValue('reisekilometer', toern.reisekilometer)
    setEinreichenDisabled(!kannEingereichtWerden(toern))
  }, [toern]);


  const kannEingereichtWerden = (toern) => {
    return (toern.endezeit) && (toern.rang) && (toern.status !== 'EINGEREICHT') && !formDirty
  }

  const assignFormDirty = (dirty) => {
    setFormDirty(dirty);
    setEinreichenDisabled(!kannEingereichtWerden(toern));
  }

  const addKommentar = async (frm) => {
    var kmt = {
      text: frm.kommentar,
    }
    if (frm.kommentar.trim().length === 0 ) {
      error("Kein Inhalt im Kommentar!")
    } else {
      await toernService.addKommentar(toern.id, kmt).then(
        res => {
          setToern(res.data)
          kommentarForm.setFieldValue('kommentar', '');
        }
      ).catch((e) => {
        error("Fehler beim Speichern des Kommentars: ", e);
      });

    }
  }

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  const showInfo = (msg) => {
    messageApi.open({
      type: 'info', content: msg,
    });
  }
  const getSchlepperFromServer = async () => {
    await schlepperService.getSchlepper().then(
      res => {
        setSchlepper(res.data.map(row => ({value: row.name})));
      }
    ).catch((e) => {
      console.log("Fehler beim Holen der Schlepper: ", e);
    });
  };

  const replaceComma = (value) => {
    return value && (typeof value === 'string' || value instanceof String) ? value.replace(',', '.') : value;
  }

  const einreichen = () => {
    toernService.toernEinreichen(toern.id)
    // TODO: Nur enablen, wenn alles valide, gespeichert und status ok!
    // TODO: Flag für Einreichen setzen
    navigate(-1);
  };

  const toernEditierbar = () => {
    return toern.status !== "EINGEREICHT" && toern.status !== "GENEHMIGT";
  }

  const onFinish = (frm) => {
    if (frm.schlepper) {
      setCookie("tug", frm.schlepper, {path: '/', expires: new Date(Date.now() + 2592000)})
    }
    var toern = {
      id: frm.toernId,
      startzeit: dayjs(frm.startzeit),
      endezeit: frm.endezeit ?  dayjs(frm.endezeit) : null,
      rang: frm.rang,
      schlepper: frm.schlepper,
      hafentage: frm.hafentage,
      reisekilometer: replaceComma(frm.reisekilometer),
      kommentar: frm.kommentar
    }

    var future = (toern.id)
      ? toernService.saveToern(authenticationService.getLoggedInUserName(), toern)
      : toernService.createToern(authenticationService.getLoggedInUserName(), toern)
    ;

    return future
      .then(res => {
        assignFormDirty(false);
        showInfo("Die Daten wurden gespeichert");
        setToern(res.data)
        if (!toern.id) {
          navigate(`/_/toern/${res.data.id}`, { replace: true });
        }
      })
      .catch((errorx) => {
          // Error
          if (errorx.response) {
            var errs = errorx.response.data;
            for (var k of Object.keys(errs)) {
              form.setFields([{name: k, errors: [errs[k]]}]);
            }
            error("Bitte prüfen Sie die Eingaben");
          } else {
            error("Es ist ein unbekannter Fehler aufgetreten. Bitte prüfen Sie die Eingaben und versuchen Sie es erneut");
          }
        }
      );
  };

  //TODO: form.isFieldsTouched

  return (
    <React.StrictMode>
      <CookiesProvider>
      {contextHolder}
      <Layout>
        <Layout.Header style={{backgroundColor: "#cccccc", display: 'flex', alignItems: 'center'}}>
          <Space>
            <Button onClick={() => navigate(-1)} icon={<RollbackOutlined/>}><span className="buttonLabel">Zurück</span></Button>
            {/*
            <Link to={`/_/toern/${toern.id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} >Ruhezeiten</Button></Link>
            */}
            <Button icon={<SendOutlined />} htmlType="button" onClick={einreichen} disabled={einreichenDisabled} >Einreichen</Button>

            <Button icon={<FilePdfOutlined/>} href={`/toern/pdf/open/toern_${toern.id}.pdf`} target={"_blank"}><span className="buttonLabel">PDF</span></Button>
            <Button icon={<DlIcon />} href={`/toern/pdf/download/toern_${toern.id}.pdf`} target={"_blank"}>
            </Button>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <LadeIndikator/>
          ) : (
            <>
            <Form
              {...layout}
              form={form}
              disabled={!toernEditierbar()}
              onFinish={onFinish}
              onChange={() => assignFormDirty(true)}
              style={{
                maxWidth: 600,
              }}
            >
              <h2>Törn {toern.id ? "bearbeiten" : "anlegen" }</h2>

              <Form.Item name={'toernId'}
                         hidden={true}
              ><Input /></Form.Item>

              <Form.Item>
                <Row>
                <Col span={8}></Col>
                <Col span={16}>
                {toernService.toernStatus(toern.id ? toern.status : "NEU")}
                </Col>
                </Row>
              </Form.Item>

              <Form.Item label="Start" name={'startzeit'}
                   rules={[{
                     required: true, message: 'Die Startzeit muss angegeben werden!',
                   }]}
              >
                <DatePicker style={{width: '100%'}}  format={"DD.MM.YYYY HH:mm"} minuteStep={30}
                            showTime={{
//                              defaultValue: now,
                              defaultValue: (dayjs().minute(0)),
//                              defaultValue: [dayjs().minute(Math.floor(dayjs().minute() / 30) * 30), dayjs().minute(Math.ceil(dayjs().minute() / 30) * 30)],
                              needConfirm: false,
                            }}
                //           defaultValue={now}
                //             onChange={() => console.log("Change Startzeit")}
                            onOk={() => console.log("OK Startzeit")} />
              </Form.Item>

              <Form.Item label="Ende" name={'endezeit'}
                   rules={[
                     (formInstance) => ({
                       message: 'Das Törnende muss nach dem Törnstart sein',
                       validator(rule, value) {
                         if (value === null) {
                           return Promise.resolve();
                         }
                         const start = formInstance.getFieldValue('startzeit');
                         if (start !== null && !value.isAfter(start)) {
                           return Promise.reject(new Error());
                         }
                         return Promise.resolve();
                       },
                     }),
                   ]}
              >
                <DatePicker style={{width: '100%'}}  format={"DD.MM.YYYY HH:mm"} minuteStep={30}
                            showTime={{
                              defaultValue: [dayjs().minute(Math.floor(dayjs().minute() / 30) * 30), dayjs().minute(Math.ceil(dayjs().minute() / 30) * 30)],
                              needConfirm: false,
                            }}
                            onChange={() => console.log("Change Endezeit")}
                            onOk={() => console.log("OK Endezeit")} />
              </Form.Item>

              <Form.Item label="Schlepper" name={'schlepper'}>
                <AutoComplete options={schlepper}
                              filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                              }
                />
              </Form.Item>
              <Form.Item label="Hafentage" name={'hafentage'}>
                <InputNumber step={.25} precision={2} style={{width: '100%'}}/>
              </Form.Item>
              <Form.Item label="Reisekosten (km)" name={'reisekilometer'}>
                <InputNumber precision={0} style={{width: '100%'}}/>
              </Form.Item>
              <Form.Item label="Rang" name={'rang'} initialValue=""
                   rules={[{
                     required: true, message: 'Der Rang muss ausgewählt werden!',
                   }]}
              >
                <Select
                  style={{width: '100%'}}
                  options={[
                    {value: 'Kapitaen', label: 'Kapitän'},
                    {value: 'Maschinist', label: 'Maschinist'},
                    {value: 'Steuermann', label: 'Steuermann'},
                    {value: 'Seemann', label: 'Seemann'},
                  ]}
                />
              </Form.Item>

              {/*

              <Form.Item label="Stundenlohn" name={'stundenlohn'}>
                {stundenlohn}
              </Form.Item>
            */}
              {/*
              <Form.Item label="Abschlag" name={'abschlag'}>
                <Typography>
                  <Input/>
                </Typography>
              </Form.Item>
                */}
              {/*

              <Form.Item label="Abschlag" name={'abschlag'}>
                <Typography>
                  <pre>Name Value: {abschlagValue}</pre>
                  <pre>Custom Value: {customValue}</pre>
                  <pre>Custom Value: {abschlag}</pre>
                </Typography>
              </Form.Item>
              */}

              { toern.id ? "" :
                <Form.Item
                  label={"Kommentar:"}
                  name={'kommentar'}
                  style={{"textAlign":"left"}}
                >
                  <Input.TextArea rows={4}/>
                 </Form.Item>
              }


              <Form.Item {...tailLayout}>
                <Space>
                  <Button icon={<RollbackOutlined />} onClick={() => navigate(-1)} >Zurück</Button>
                  <Button icon={formDirty ? <SaveOutlined style={{ color: red[4] }} /> : <SaveOutlined  />} htmlType="submit" >{/* disabled={disabledSave} disabled={disabledSaveAndSubmit} */}
                    Speichern
                  </Button>
                  { formDirty ?
                    <Popconfirm title="Speichern" description={<>Sollen ungespeicherte Änderungen gespeichert werden?</>}
                                onConfirm={() => { onFinish(form.getFieldsValue(true)).then(() => einreichen())} } okText="Ja" cancelText="Nein">
                                  <Button icon={<SendOutlined />} type="primary" htmlType="button"
                                  disabled={einreichenDisabled} >Einreichen</Button>
                    </Popconfirm> :
                    <Button icon={<SendOutlined />} type="primary" htmlType="button" onClick={einreichen} disabled={einreichenDisabled} >
                      Einreichen
                    </Button>
                  }
                </Space>
              </Form.Item>
            </Form>

            { toern.id ?

            <Form
              {...layout}
              onFinish={addKommentar}
              form={kommentarForm}
              style={{
                maxWidth: 600,
              }}>

              <h2>Törn-Log</h2>

              <Form.Item
                label={"Kommentare:"}
                name={'kommentar'}
                style={{"textAlign":"left", marginBottom: 0}}
              >
                <Input.TextArea rows={4}/>
              </Form.Item>

              <Form.Item className={"emptyLabel"}
                label={" "}
                style={{"textAlign":"left"}}
              >
                <Button icon={<MessageOutlined />} htmlType="submit" >
                  Kommentieren
                </Button>
              </Form.Item>

              <Form.Item
                label={"Historie"}
                style={{"textAlign":"left"}}
              >

                <Space direction="vertical" style={{"width":"100%"}}>

                {toern && toern.kommentare && toern.kommentare && toern.kommentare.map(k =>
                  <Comment
                    key={k.id}
                  author={(k.benutzer && k.benutzer.vorname + " " + k.benutzer.nachname) || "Unbekannt"}
                  avatar={<Avatar className={ k.benutzer.bordpersonal ? 'avatarBord' : 'avatarNonBord' } size="middle" gap={1}>
                    { k.benutzer && k.benutzer.initialen }
                  </Avatar>}
                  content={<>{k.tags ? k.tags.map((t,idx) => <Tag key={"" + k.id + "+" + t.id } color={t==="Rückfrage"?"red":(t==="Genehmigt"?"red":"blue")}>{t}</Tag>) : null}{k.text}</>}
                  datetime={dayjs(new Date(k.zeit)).format('DD.MM.YYYY HH:mm')}
                  />)
                }
                {/*
                <Comment
                  actions={[<a>ddd</a>]}
                  //  actions={item.actions}
                  author="Y.Bord"
                  avatar={<Avatar className='avatarNonBord'  style={{ backgroundColor: '#e33c40' , verticalAlign: 'middle' }} size="middle" gap={1}>
                    YvB
                  </Avatar>}
                  content={<><Tag>Bla</Tag>'Die ist ein Platzhalter für Kommentare'</>}
                  datetime='2023-12-12'
                />
                */}
                </Space>

              </Form.Item>

            </Form>
              : "" }
            </>

          )}


        </Content>
      </Layout>
      </CookiesProvider>
    </React.StrictMode>
  )
}
export default ToernBearbeitenSeite

