import axios from 'axios'
import ServiceConfig from "../services/ServiceConfig";
import {
  CheckCircleOutlined,
  CheckSquareOutlined,
  EditOutlined, ExclamationCircleOutlined, FileAddOutlined,
  QuestionCircleOutlined,
  StopOutlined
} from "@ant-design/icons";
import {Avatar} from "antd";
import React from "react";
import {Link} from "react-router-dom";

const API_URL = ServiceConfig.API_URL;

class ToernService {

  getAktiveToerns(email) {
    return axios.get(`${API_URL}/api/v1/toern/aktiv?email=${email}`);
  }

  getUserToernsFuerMonat(email, datum) {
    return axios.get(`${API_URL}/api/v1/toern/user/monat/` + datum.year() + "/" + (1+datum.month()),  {
      params: {
        email: email
      }
    });
  }

  getToernsFuerMonat(datum) {
    return axios.get(`${API_URL}/api/v1/toern/monat/` + datum.year() + "/" + (1+datum.month()));
  }
  getZuPruefendeTorns() {
    return axios.get(`${API_URL}/api/v1/toern/eingereicht/`);
  }

  getToern( id) {
    return axios.get(`${API_URL}/api/v1/toern/user/${id}`);
  }

  createToern(email, toern) {
    return axios.post(`${API_URL}/api/v1/toern?email=${email}`, toern )
  }

  saveToern(email, toern) {
    return axios.patch(`${API_URL}/api/v1/toern/${toern.id}?email=${email}`, toern )
  }

  deleteToern(email, id) {
    return axios.delete(`${API_URL}/api/v1/toern/${id}?email=${email}`)
  }

  getRuhezeitenFuerToern(toernId) {
    return axios.get(`${API_URL}/api/v1/toern/ruhezeiten/${toernId}`);
  }

  getRuhezeit(id) {
    return axios.get(`${API_URL}/api/v1/toern/ruhezeit/${id}`);
  }

  createRuhezeit(email, toernId, ruhezeit) {
    return axios.post(`${API_URL}/api/v1/toern/ruhezeiten/` + toernId + "?email=" + email, ruhezeit )
  }

  saveRuhezeit(email, ruhezeit) {
    return axios.patch(`${API_URL}/api/v1/toern/ruhezeit/${ruhezeit.id}?email=${email}`, ruhezeit )
  }

  deleteRuhezeit(email, id) {
    return axios.delete(`${API_URL}/api/v1/toern/ruhezeit/${id}?email=${email}`)
  }

  addKommentar(toernId, kommentar) {
    return axios.post(`${API_URL}/api/v1/toern/kommentar/${toernId}`, kommentar );
  }

  toernEinreichen(toernId) {
    return axios.post(`${API_URL}/api/v1/toern/einreichen/${toernId}` );
  }

  toernAblehnen(toernId, kommentar) {
    return axios.post(`${API_URL}/api/v1/toern/ablehnen/${toernId}`, kommentar );
  }

  toernGenehmigen(toernId) {
    return axios.post(`${API_URL}/api/v1/toern/genehmigen/${toernId}`);
  }


  toernStatus(status, long=true) {
    var title;
    var icon;
    var color;
    switch (status) {
      case "NEU" :
        title = 'Neuer Törn';
        icon = <FileAddOutlined />;
        color = '#1677ff';
        break;
      case 'ERFASSUNG' :
        title = 'In Erfassung'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <EditOutlined />
        color = '#fadb14'
        break;
      case 'EINGEREICHT' :
        title = 'Stunden werden geprüft'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <CheckSquareOutlined />
        color = '#bbbbbb'
        break;
      case 'RÜCKFRAGE' :
        title = 'Rückfrage'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <QuestionCircleOutlined />
        color = '#cf1322'
        break;
      case 'GENEHMIGT' :
        title = 'Genehmigt'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <CheckCircleOutlined />
        color = '#389e0d'
        break;
      default:
        title = 'Unerwarteter Törnstatus'; // 'Stunden einreichen', 'Stunden prüfen'
        icon = <ExclamationCircleOutlined />
        color = '#cf1322'
    }
    return <>
      <Avatar size={"small"} style={{backgroundColor: color}} icon={icon}/>
      { long ? " " + title : ""}
    </>
  }


  ///////////////////
/*



    holeMonat(username, datum) {
        return axios.get(`${API_URL}/api/v1/toern/${username}/monat/` + datum.year() + "/" + datum.month() );
    }

    holeMonatsAnsicht(username) {
        return axios.get(`${API_URL}/api/v1/monatsansicht/` + username)
    }

    createToern(username, toern) {
        return axios.post(`${API_URL}/api/v1/toern/${username}`, toern )
    }

    saveToern(username, toern) {
        return axios.patch(`${API_URL}/api/v1/toern/${username}/${toern.id}`, toern )
    }



 */

}

const toernService =  new ToernService();
export default toernService;