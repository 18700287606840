import React, {useEffect, useState} from 'react'
import {
  Button,
  Layout,
  Table,
  message,
  Spin,
  DatePicker,
  Space,
  Popconfirm
} from 'antd';
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined,
  WarningTwoTone
} from "@ant-design/icons";
import _ from "lodash";
import toernService from "../../services/ToernService";
import authenticationService from "../../services/AuthenticationService";
import dayjs from "dayjs";
import TableTitle from "../base/TableTitle";
import {MeinToernIcon} from "../icons/CustomIcons";

const { Content } = Layout;

const MeineToernsListe = () => {
  let {jahr, monat} = useParams();

  const pMonat = jahr && monat ? dayjs(jahr + "-" + monat + "-01 00:00") : dayjs();

  const [messageApi, contextHolder] = message.useMessage();

  const [datum, setDatum] = useState(() => pMonat);
  const [toernliste, setToernliste] = useState([]);
  const [loading, setloading] = useState(true);
  const [feldFilter, setFeldFilter] = useState({});
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate = useNavigate();

  const error = (msg) => {
    messageApi.open({
      type: 'error', content: msg,
    });
  }

  useEffect(() => {
    if (datum !== null) {
      getToernsProMonat();
    } else {
      setToernliste([]);
    }
  }, [datum]);

  const handleChange = (pagination, filters, sorter) => {
    setFeldFilter(filters);
  };

  const confirmToernDeleted = async () => {
    messageApi.success('Der Törn wurde gelöscht');
  }
  const deleteToern = async (id) => {
    // TODO: Rechte prüfen!
    await toernService.deleteToern(authenticationService.getLoggedInUserName(), id).then(
      () => {
        getToernsProMonat();
        confirmToernDeleted();
      }
    ).catch((e) => {
      error(`Beim Löschen des Toerns ist ein Fehler aufgetreten`);
      console.error(e);
    });
  }

  const getToernsProMonat = async () => {
    console.log("getToernsProMonat " + datum.year() + "-" + datum.month() )
    setloading(true);
    await toernService.getUserToernsFuerMonat(authenticationService.getLoggedInUserName(), datum).then(
      res => {
        setloading(false);
        setToernliste(
          res.data.map(row => ({
            key: `t_${row.id}`,
            id: row.id,
            startzeit: row.startzeit,
            endezeit: row.endezeit,
            schlepper: row.schlepper,
            rang: row.rang,
            benutzerrang: row.benutzerrang,
            ruhezeitenfehler: row.ruhezeitenfehler,
            ruhezeitenfehleranzahl: row.ruhezeitenfehleranzahl,
            ruhezeiten: row.ruhezeiten,
            status: row.status,
            hafentage: row.hafentage,
            reisekilometer: row.reisekilometer,
//            stundenlohn: row.stundenlohn,
//            abschlag: row.abschlag
          }))
        )
      }
    ).catch((e) => {
      setloading(false);
      error(`Beim Laden der Törns ist ein Fehler aufgetreten:`);
      console.error(e);
    });
  };

  const tabletitle = () => {
    return <TableTitle icon={<MeinToernIcon/>}
                  filtervalues={
                    toernliste
                      .map(t => t.schlepper)
                      .filter((value, index, array) => array.indexOf(value) === index)
                      .map(s => ({ label: s, value: s}))
                  }
                  onFilter={setGlobalFilter} >Meine Törns</TableTitle>
  }

  const filterData = toernliste => formatter => toernliste.map( item => ({
    text: formatter(item),
    value: formatter(item)
  }));

  const changeMonat = (d) => {
    setDatum(d)
    console.log(
      "d " + d.year()
    )
    navigate("/_/toerns/meine/"  + d.format("YYYY/MM") , { replace: true})
//    Navigate({to:"/_/zeit/meine/2024/04", replace:true})
    //             <DatePicker defaultValue={datum} format={'MMM YYYY'} picker="month"
    //                       onChange={ (ev) => setDatum(ev ? dayjs(ev.$d) : dayjs()) } />

  }

  const columns=[
    {
      title: '',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => <>{toernService.toernStatus(record.status, false)}</>
    },
    {
      title: 'Schlepper',
      dataIndex: 'schlepper',
      key: 'schlepper',
      sorter: (a, b) => a.schlepper.localeCompare(b.schlepper),
      filters: _.uniqWith(filterData(toernliste)(i => i.schlepper), _.isEqual),
      filteredValue: feldFilter.schlepper || null,
      onFilter: (value, record) => record.schlepper.includes(value),
    },
    // TODO: Status
    // TODO: Manuelle Logeinträge?
    //             <DatePicker defaultValue={datum} format={'MMM YYYY'} picker="month"
    //                       onChange={ (ev) => setDatum(ev ? dayjs(ev.$d) : dayjs()) } />
    {
      title: 'Von',
      dataIndex: 'startzeit',
      key: 'startzeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.startzeit.localeCompare(b.startzeit),
    },
    {
      title: 'Bis',
      dataIndex: 'endezeit',
      key: 'endezeit',
      render: (datum) => <>{datum ? dayjs(new Date(datum)).format('DD.MM.YYYY HH:mm'): ""}</>,
      sorter: (a, b) => a.endezeit.localeCompare(b.endezeit),
    },
    {
      title: 'Rang',
      dataIndex: 'rang',
      key: 'rang',
      render: (rang, record) => <>{rang} {(rang !== record.benutzerrang) ? (<WarningTwoTone  title={"Benutzer: " + record.benutzerrang } />) : ""
          }</>,
    },
    {
      title: 'Hafentage',
      dataIndex: 'hafentage',
      key: 'hafentage',
      render: (hafentage) => hafentage ? new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(hafentage) : "",
    },
    {
      title: 'Kilometer',
      dataIndex: 'reisekilometer',
      key: 'reisekilometer',
    },
    /*
    {
      title: <HourglassTwoTone title={"Ruhezeiten"} />,
      dataIndex: 'ruhezeitenfehler',
      key: 'ruhezeitenfehler',
      render: (fehler, record) => <Space>
        <Badge size={"small"} count={record.ruhezeiten ? record.ruhezeiten.length : "0"}>
          <Link to={`/_/toern/${record.id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} /></Link>
        </Badge>
        {(fehler) ?
          (<>
            <Badge size={"small"} count={record.ruhezeitenfehleranzahl}><Avatar icon={<WarningOutlined />} title={fehler} size="small"  /></Badge>
          </>)
          : "" }</Space>,
    },
    */
    {
      title: 'Aktionen',
      dataIndex: 'id',
      key: 'id',
      filteredValue: globalFilter ? [globalFilter] : null,
      onFilter: (value, record) => record.schlepper.toString().toLowerCase().includes(value.toLowerCase()),
      render: (id, record) => <Space>
        <Link to={"/_/toern/" + id}><Button icon={<EditOutlined />} /></Link>
        <Popconfirm title="Törn löschen" disabled={record.status !== 'ERFASSUNG'} description={<>Soll der Törn vom<br/><i>{dayjs(record.startzeit).format('DD.MM.YYYY HH:mm')}</i><br/>unwideruflich gelöscht werden?</>} onConfirm={() => deleteToern(id)} okText="Ja" cancelText="Nein">
          <Button disabled={record.status !== 'ERFASSUNG'} icon={< DeleteOutlined />} type="primary" danger title={"Törn löschen"} />
        </Popconfirm>
        {/*
        <Link to={`/_/toern/${id}/ruhezeiten`}><Button icon={<HourglassOutlined/>} /></Link>
        */}
      </Space>,
    }
  ]

  return (
    <>
      {contextHolder}
      <Layout>
        <Layout.Header style={{ backgroundColor: "#cccccc", display: 'flex',  alignItems: 'center'}}>
          <Space>
            <DatePicker defaultValue={datum} format={'MMM YYYY'} picker="month" onChange={changeMonat} />
            <Link to={"/_/toern/neu"}><Button icon={<UserAddOutlined/>} >Neuen Törn erfassen</Button></Link>
          </Space>
        </Layout.Header>
        <Content>
          {loading ? (
            <div style={{ padding: '16px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Table title={tabletitle} columns={columns} dataSource={toernliste} onChange={handleChange}></Table>
          )}
        </Content>
      </Layout>
    </>
  )
}

export default MeineToernsListe