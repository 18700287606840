import {AutoComplete, Col, Input, Row} from "antd";
import React, {useState} from "react";
import './FormHeader.css';
import {FilterFilled} from "@ant-design/icons";

const TableTitle = ({
                   icon = null,
                  filtervalues = [],
                  onFilter = null,
                   children,

                 }) => {

  const [filterwert, setFilterwert] = useState("");

  const onFilterChange = (fw) => {
    setFilterwert( fw !== "" ? fw : null)
    if (onFilter) {
      onFilter(fw)
    }
  }

  return <Row gutter={[0,0]}>
    <Col flex="240px"><h2 style={{margin:'0px'}}>{icon} {children}</h2></Col>
    <Col flex="auto" style={{textAlign:'right'}}>
      <AutoComplete
        style={{marginLeft: 'auto', width:'250px', marginRight:'32px'}}
        options={filtervalues}
        onChange = {onFilterChange}
        value={filterwert}
        filterOption={(inputValue, option) =>
          option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      >
        <Input placeholder="Filtern" addonBefore={<FilterFilled style={(filterwert)? {color: '#1677ff'}:{color: 'rgba(0, 0, 0, 0.29)'}} />} allowClear={true}/>
      </AutoComplete>
    </Col>
  </Row>
};

export default TableTitle